<template>
  <div class="tariffList" >

      <mini-loader v-if="load" />
      <div class="card" style="overflow-x: auto; max-width: 100%">
        <div class="card-header d-flex justify-content-between align-items-center">
          <h5 class="text-dark">Тарифы</h5>
          <router-link to="/tariffs/create">
            <span class=" p-1">
              <button type="button" class="btn btn-success">Добавить
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 20 20">
                  <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"/>
                </svg>
              </button>

            </span>
          </router-link >
        </div>
        <table class="table table-striped" id="tariffList" >
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Наименование</th>
              <th scope="col">Описание</th>
              <th scope="col">Тип тарифа</th>
              <th scope="col">Статус</th>
              <th scope="col">Тип груза</th>
              <th scope="col">Филиал</th>
              <th scope="col">Тип услуги</th>
              <th scope="col">Тип цены</th>
              <th scope="col">Тип клиента</th>
              <th scope="col">Действие</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(value, index) in data">
              <th scope="row">{{index+1}}</th>
              <td>{{value.title}}</td>
              <td>{{value.description}}</td>
              <td v-if="value.type=='international'"> <span class="btn btn-success">Международный</span> </td> <td v-else> <span class="btn btn-info">Местный</span> </td>
              <td v-if="value.status=='inactive'"> <span class="btn btn-danger">Неактивный</span> </td> <td v-else> <span class="btn btn-success">Активный</span> </td>
              <td> <span v-if="value.type_of_cargo_id">{{value.type_cargo.title}}</span> <span v-else> Все</span> </td>
              <td v-if="value.branch">{{value.branch.title}}</td> <td v-else>--</td>
              <td v-if="value.type_service">{{value.type_service.title}}</td> <td v-else>--</td>
              <td v-if="value.price_type == 'linear'">Линейный</td> <td v-else>Нелинейный</td>
              <td v-if="value.client_type == 'individual'">Физ.лицо</td> <td v-else-if="value.client_type == 'entity'">Юр.лицо</td> <td v-else></td>
              <td style="display: flex">
                <a  @click="showItem(value)">
                  <span class="border rounded border-primary m-1 p-2 text-primary">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                      <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                      <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                    </svg>
                  </span>
                </a>
                <router-link :to="'/tariffs/edit/' + value.id">
                  <span class="border rounded border-success m-1 p-2 text-success">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                      <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                    </svg>
                  </span>
                </router-link>
                <a @click="deleteItem(index, value)">
                  <span class="border rounded border-danger m-1 p-2 text-danger">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                      <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                    </svg>
                  </span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <p class="m-10 text-dark text-center" style="border: 1px solid #DFE1E4; padding: 10px">1 - {{data.length}} данных  из {{totalResults}}</p>
      </div>


    <!-- Delete modal -->
    <a-modal v-model="visibleDeleteModal" title="Подтверждение на удаление" ok-text="Удалить" cancel-text="Отменить" @ok="destroyItem">
      <h6 style="color: red">Точно хотите безвозвратно удалить ?</h6>
    </a-modal>
  </div>


</template>

<script>
import axios  from 'axios'
const data = '';
export default {
  data() {
    return {
      data,
      item: [],

      ind: -10,
      visible: false,
      visibleShowModal: false,
      visibleEditModal: false,
      visibleDeleteModal: false,
      visibleCreateModal: false,
      load: true,


      currentPage: 1,
      totalResults: 0,
      scrolled: false,
      old_height: 0,
      last_page: 1,
      index: ''
    }
  },
  mounted() {
    axios.get('/tariffs').then((response)=>{ console.log(response);
        if(response.status == 200){
          this.data = response.data.data;
          this.totalResults = response.data.total;
          this.last_page = response.data.last_page;
        }else if(response.status == 204){
          this.$message.error(
            'Тарифов не найдено',
            3
          );
        }
     }).catch((error)=>{
       if(error.response.status===401){
           this.$router.push('/pages/login');
       }else if(error.response.status == 500){
         this.$message.error(
           'Ошибка сервера',
           3
         );
       }
     }).finally(() => {this.load = false});

     window.onscroll = () => {
         let height = document.getElementById('tariffList').clientHeight
         console.log(height);
         if ((window.innerHeight + document.documentElement.scrollTop) >= height) {
           if(this.old_height!=height){
             this.old_height = height;
             let limit = this.data.length/this.$store.state.paginateCount + 1;
             if(limit <= this.last_page){
               this.load = true;
               axios.get('/tariffs', {params: {page: limit}}).then(response => {
                 if(response.status == 200){
                     this.totalResults = response.data.total;
                     this.data = this.data.concat(response.data.data);
                     this.load = false
                 }else if(response.status == 204){
                   this.$message.error(
                     'Тарифов не найдено',
                     3
                   );
                 }
               }).catch(error => {
                 if(response.status == 204){
                   this.$message.error(
                     'Тарифов не найдено',
                     3
                   );
                 }
                 this.load = false
               });
             }
           }
         }
     };

  },
  methods: {
    createItem(){

    },
    editItem(){

    },
    deleteItem(index, value){
      this.index = index;
      this.item = value;
      this.visibleDeleteModal = true;
    },
    storeItem(){

    },
    updateItem(){

    },
    destroyItem(){
      axios.delete('/tariffs/'+this.item.id).then(response => {
         if(response.status==200){
          this.$message.success(
                response.data.success,
                3,
          );
          this.data.splice(this.index, 1)
          this.totalResults--;
        }
      }).catch(error => {
        if(error.response.status==400){
          this.$message.error(
                error.response.data.error,
                3,
          );
        }else if(error.response.status == 404){
          this.$message.error(
                error.response.data.error,
                3,
          );
        }else if(error.response.status == 500){
          this.$message.error(
                'Ошибка сервера',
                3,
          );
        }
      }).finally(() => {
          this.visibleDeleteModal = false
      });
    },
  }
}
</script>

<style>

</style>
